import { useEffect } from "react";
import { useOrderKitsData } from "hooks/useOrderKits";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { Routes, Route } from "react-router";
import { Link } from "react-router-dom";
import Checkout from "./components/Checkout";
import Order from "./components/Order";
import { usePaymentAndUpload } from "hooks/usePaymentAndUpload";
import { useUser } from "state/user/hooks";


const ShowOrders = () => {
  // Fetch orders from Django API
  const { fetchOrderKits, orderKitsData, isLoading } = useOrderKitsData();
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  useEffect(() => {
    fetchOrderKits();
  }, [fetchOrderKits]);

  const { user } = useUser();
  const isSuperAdmin = user?.is_SuperAdmin;
  const basisRatio = isSuperAdmin? "basis-1/5" : "basis-1/3";
  const basisPadding = isSuperAdmin? "pl-0" : "pl-9";

  // Display orders here
  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="pt-14 bg-main">
        <div className="flex flex-col justify-center">
          <div className="px-[40px] w-full">
            <div className="overflow-x-auto w-full sm:w-9/12 mx-auto">
              <div className="flex flex-row">
                <div className="basis-1/2"><h1 className="text-4xl">Orders</h1></div>
                <div className="basis-1/2">
                  <Link to="/orders/checkout" className="py-3 px-4 rounded-md px-[30px] text-xl bg-white max-w-[200px] float-right">+ New Order</Link>
                </div>
              </div>
              <div className="bg-[#FCFCFC] pt-8 my-8">
                <div className="flex flex-row text-xl pb-6 italic">
                {isSuperAdmin &&
                  <>
                    <span className="basis-1/6 pl-4">Id</span>
                    <span className="basis-1/5">Coach</span>
                  </>
                }
                  <span className={[basisRatio, basisPadding].join(" ")}>Client Name</span>
                  <span className={basisRatio}>Status</span>
                  <span className={basisRatio}>Date Created</span>
                </div>
                {orderKitsData && orderKitsData.map((order, i) => (
                  <div className="flex flex-row py-4 border-t font-helvetica" key={i}>
                    {isSuperAdmin &&
                      <>
                        <span className="basis-1/6 pl-4 underline"><Link to={"/orders/" + order.id}>{order.id}</Link></span>
                        <span className="basis-1/5">{order.coach_name}</span>
                      </>
                    }
                    <span className={[basisRatio, basisPadding, "underline"].join(" ")}><Link to={"/orders/" + order.id}>{order.attention}</Link></span>
                    <span className={basisRatio}>{order.status}</span>
                    <span className={basisRatio}>{new Date(Date.parse(order.date_created)).toLocaleDateString('en-US', options)}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DataLoaderOverlay>
  );
}

export default function Orders() {
  const { isLoading, processCheckoutOnly, getUserData, getProducts } =
    usePaymentAndUpload();
  return (
    <Routes>
      <Route index element={<ShowOrders />} />
      <Route path="/checkout" element={<Checkout 
        isLoading={isLoading}
        processCheckoutOnly={processCheckoutOnly}
        getUserData={getUserData}
        getProducts={getProducts}
        />} 
      />
      <Route path="/:orderId" element={<Order isLoading={isLoading}/>} />
    </Routes>
  );
}
